import noop from 'lodash/noop';

const safeGlobalContext = {
  alert: noop,
  prompt: noop,
  confirm: noop,
  window:
    typeof window === 'undefined'
      ? {}
      : {
          location: window.location,
          open: (...args: Parameters<typeof window.open>) => window.open(...args),
          opener: window.opener as Window | null,
          addEventListener: (...args: Parameters<typeof window.addEventListener>) =>
            window.addEventListener(...args),
          removeEventListener: (...args: Parameters<typeof window.removeEventListener>) =>
            window.removeEventListener(...args),
        },
};

// To eval single javascript expression or statement and get its resolved value use indirectEvalExpression
export const indirectEvalExpression = (script: string, globalContext: object): unknown => {
  const context = { ...safeGlobalContext, ...globalContext };
  const keys = Object.keys(context);
  const values: unknown[] = Object.values(context);
  // escape newline characters to avoid "Invalid or unexpected token" error
  // we need to preserve the newline characters in the script to maintain formatting, eg. markdown default value
  // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func -- needed
  const evalFunction = new Function(
    ...keys,
    `"use strict"; return ${script.replace(/\n/g, '\\n')}`,
  );
  return evalFunction(...values);
};

// To execute any javascript code, which doesnt return anything with global context use indirectExecuteScript
export const indirectExecuteScript = (script: string, globalContext: object) => {
  const context = { ...safeGlobalContext, ...globalContext };
  const keys = Object.keys(context);
  const values: unknown[] = Object.values(context);
  // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func -- needed
  const evalFunction = new Function(...keys, `"use strict"; ${script}`);
  evalFunction(...values);
};
