import type { SimpleOps as SimpleOpsType } from '@unifyapps/network/generated/models/simpleOps';
import type { UIFilter as UIFilterType } from '@unifyapps/network/generated/models/uIFilter';
import { Filter } from './Filter';
import { Op } from './Op';

export class CompoundOps {
  static readonly AND = new CompoundOps(Op.AND);
  static readonly OR = new CompoundOps(Op.OR);

  constructor(private readonly op: Op) {}

  getOp(): Op {
    return this.op;
  }
}

class UIFilterBlock {
  operator: SimpleOps;
  value?: string;

  constructor(operator: SimpleOps, value?: string) {
    this.operator = operator;
    this.value = value;
  }
}

export class SimpleOps {
  static readonly EQUAL = new SimpleOps(Op.EQUAL);
  static readonly NOT_EQUAL = new SimpleOps(Op.NOT_EQUAL);
  static readonly CONTAINS = new SimpleOps(Op.CONTAINS);
  static readonly NOT_CONTAINS = new SimpleOps(Op.NOT_CONTAINS);
  static readonly REGEX = new SimpleOps(Op.REGEX);
  static readonly NOT_REGEX = new SimpleOps(Op.NOT_REGEX);
  static readonly IREGEX = new SimpleOps(Op.IREGEX);
  static readonly NOT_IREGEX = new SimpleOps(Op.NOT_IREGEX);
  static readonly EXISTS = new SimpleOps(Op.EXISTS);
  static readonly MISSING = new SimpleOps(Op.MISSING);
  static readonly LT = new SimpleOps(Op.LT);
  static readonly LTE = new SimpleOps(Op.LTE);
  static readonly GT = new SimpleOps(Op.GT);
  static readonly GTE = new SimpleOps(Op.GTE);
  static readonly MIN_LENGTH = new SimpleOps(Op.MIN_LENGTH);
  static readonly MAX_LENGTH = new SimpleOps(Op.MAX_LENGTH);

  static readonly DATE_IS_BEFORE = new SimpleOps(Op.DATE_IS_BEFORE);
  static readonly DATE_IS_AFTER = new SimpleOps(Op.DATE_IS_AFTER);
  static readonly DATE_IS_ON_OR_BEFORE = new SimpleOps(Op.DATE_IS_ON_OR_BEFORE);
  static readonly DATE_IS_ON_OR_AFTER = new SimpleOps(Op.DATE_IS_ON_OR_AFTER);

  static readonly TIME_IS_EQUAL_TO_OR_AFTER = new SimpleOps(Op.TIME_IS_EQUAL_TO_OR_AFTER);
  static readonly TIME_IS_EQUAL_TO_OR_BEFORE = new SimpleOps(Op.TIME_IS_EQUAL_TO_OR_BEFORE);
  static readonly TIME_IS_BEFORE = new SimpleOps(Op.TIME_IS_BEFORE);
  static readonly TIME_IS_AFTER = new SimpleOps(Op.TIME_IS_AFTER);
  static readonly TIME_IS_EQUAL_TO = new SimpleOps(Op.TIME_IS_EQUAL_TO);

  // static readonly BETWEEN = new SimpleOps(Op.BETWEEN);
  // static readonly STARTS_WITH = new SimpleOps(Op.STARTS_WITH);
  // static readonly ENDS_WITH = new SimpleOps(Op.ENDS_WITH);
  // static readonly ICONTAINS = new SimpleOps(Op.ICONTAINS);
  // static readonly NOT_ICONTAINS = new SimpleOps(Op.NOT_ICONTAINS);

  constructor(private readonly op: Op) {}

  getOp(): Op {
    return this.op;
  }

  toString(): string {
    return this.op.toString();
  }
}

const simpleOpsMap: Partial<Record<SimpleOpsType, SimpleOps>> = {
  EQUAL: SimpleOps.EQUAL,
  NOT_EQUAL: SimpleOps.NOT_EQUAL,
  CONTAINS: SimpleOps.CONTAINS,
  NOT_CONTAINS: SimpleOps.NOT_CONTAINS,
  REGEX: SimpleOps.REGEX,
  NOT_REGEX: SimpleOps.NOT_REGEX,
  IREGEX: SimpleOps.IREGEX,
  NOT_IREGEX: SimpleOps.NOT_IREGEX,
  // @ts-expect-error -- need to use FE type
  MIN_LENGTH: SimpleOps.MIN_LENGTH,
  MAX_LENGTH: SimpleOps.MAX_LENGTH,
  EXISTS: SimpleOps.EXISTS,
  MISSING: SimpleOps.MISSING,
  LT: SimpleOps.LT,
  LTE: SimpleOps.LTE,
  GT: SimpleOps.GT,
  GTE: SimpleOps.GTE,

  DATE_IS_BEFORE: SimpleOps.DATE_IS_BEFORE,
  DATE_IS_AFTER: SimpleOps.DATE_IS_AFTER,
  DATE_IS_ON_OR_BEFORE: SimpleOps.DATE_IS_ON_OR_BEFORE,
  DATE_IS_ON_OR_AFTER: SimpleOps.DATE_IS_ON_OR_AFTER,

  TIME_IS_EQUAL_TO_OR_AFTER: SimpleOps.TIME_IS_EQUAL_TO_OR_AFTER,
  TIME_IS_EQUAL_TO_OR_BEFORE: SimpleOps.TIME_IS_EQUAL_TO_OR_BEFORE,
  TIME_IS_BEFORE: SimpleOps.TIME_IS_BEFORE,
  TIME_IS_AFTER: SimpleOps.TIME_IS_AFTER,
  TIME_IS_EQUAL_TO: SimpleOps.TIME_IS_EQUAL_TO,

  // BETWEEN: SimpleOps.BETWEEN,
  // STARTS_WITH: SimpleOps.STARTS_WITH,
  // ENDS_WITH: SimpleOps.ENDS_WITH,
  // ICONTAINS: SimpleOps.ICONTAINS,
  // NOT_ICONTAINS: SimpleOps.NOT_ICONTAINS,
};

export class UIFilter {
  _operator?: CompoundOps | SimpleOps;
  _filters?: UIFilter[];
  _property: string;
  _filter?: UIFilterBlock;

  getOperator() {
    return this._operator;
  }

  setOperator(operator: CompoundOps | SimpleOps) {
    this._operator = operator;
  }

  getFilters() {
    return this._filters;
  }
  setFilters(filters: UIFilter[]) {
    this._filters = filters;
  }

  getProperty() {
    return this._property;
  }
  setProperty(property: string) {
    this._property = property;
  }

  toFilter(): Filter | null {
    if (this._operator && this._filters) {
      const filters = this._filters.map((uiFilter) => uiFilter.toFilter());
      return new Filter().withOp(this._operator.getOp()).withValues(filters);
    } else if (this._filter) {
      return new Filter()
        .withField(this._property)
        .withOp(this._filter.operator.getOp())
        .withValues([this._filter.value]);
    }
    return null;
  }

  static createUIFilter(uiFilter: UIFilterType): UIFilter {
    const filter = new UIFilter();
    if (uiFilter.operator && uiFilter.filters) {
      filter.setOperator(uiFilter.operator === 'AND' ? CompoundOps.AND : CompoundOps.OR);
      filter.setFilters(uiFilter.filters.map((f) => UIFilter.createUIFilter(f)));
    }

    if (uiFilter.property !== undefined && uiFilter.filter?.operator) {
      filter.setProperty(uiFilter.property);
      const op = simpleOpsMap[uiFilter.filter.operator];
      if (!op) {
        throw new Error(`Invalid operator: ${uiFilter.filter.operator}`);
      }
      filter._filter = new UIFilterBlock(op, uiFilter.filter.value);
    }
    return filter;
  }
}
